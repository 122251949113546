import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, flatMap, map } from 'rxjs/operators';

import { Router } from '@angular/router';
import { UserService } from './user.service';
import { Credentials } from '../models/Credentials';
import { RegisterUser } from '../models/RegisterUser';
import { Token } from '../models/Token';
import { User } from '../models/User';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';



@Injectable()
export class AuthService implements OnDestroy{

  baseUrl = environment.baseUrl;
  logoutSubscription: Subscription;

  constructor(private http: HttpClient, private router: Router, private user: UserService) {
  }

  ngOnDestroy() {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }


  registerNewUser(data: RegisterUser) {
    if (!data.role_id) {
      delete data.role_id;
    }
    if (!data.user_subgroup_id) {
      delete data.user_subgroup_id;
    }
    const url = this.baseUrl + 'auth/register';
    return this.http.post(url, data)
      .pipe(
        map((el: any) => {
          return el.data;
        })
      );
  }

  changePassword(data) {
    const url = this.baseUrl + 'user/change_password';
    return this.http.post(url, data);
  }

  sendInstructions(data) {
    const url = this.baseUrl + 'auth/recovery';
    return this.http.post(url, data);
  }

  resetPassword(data) {
    const url = this.baseUrl + 'auth/reset';
    return this.http.post(url, data);
  }

  login(params: Credentials) {
    const url = this.baseUrl + 'auth/login';
    return this.loginRequest(url, params);
  }

  // Login admin as user
  loginAsUser(id) {
    const url = this.baseUrl + 'user/login';
    return this.loginRequest(url, {id: id});
  }

  private loginRequest(url, params) {
    return this.http.post(url, params)
      .pipe(
        flatMap(
          (tokenData: Token) => {
            this.setTokenData(tokenData);
            // now get ME object:
            return this.getMe()
              .pipe(
                tap(
                  (meData: User) => {
                    this.user.setMe(meData);
                    return meData;
                  },
                  (error) => {
                    this.logout(false);
                    return error;
                  }
                )
              );
          }
        )
      );
  }

  logout(navigateHome = true, sendToApi = true) {
    if (sendToApi) {
      const url = this.baseUrl + 'auth/logout';
      this.logoutSubscription = this.http.post(url, {}).subscribe(()=>{}); // must be before we delete token
    }

    localStorage.removeItem('token');
    this.user.setMe(null);
    if (navigateHome) {
      this.router.navigate(['/auth/login']);
    }
  }


  private setTokenData(tokenData: Token) {
    localStorage.setItem('token', tokenData.token_type + ' ' + tokenData.access_token);
  }


  acceptTerms() {
    const url = this.baseUrl + 'user/accept_terms';
    return this.http.put(url, null).pipe(
      tap(
        (data: any) => {
          // update ME object with accept_terms = 1
          const me = this.user.me;
          me.accept_terms = 1;
          this.user.setMe(me);
          return data;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  get isLoggedIn(): boolean {
    const me = this.user.me;
    if (me && localStorage.getItem('token')) {
      if (me.accept_terms === 1) { // also check terms and condition
        return true;
      }
    }
    return false;
  }

  refreshToken() {
    const url = this.baseUrl + 'auth/refresh';
    return this.http.post(url, {})
      .pipe(
        tap(
          (data: Token) => {
            this.setTokenData(data);
            return data;
          }
        )
      );
  }

  getMe() {
    const url = this.baseUrl + 'auth/me';
    return this.http.get(url).pipe(
      map((data: any) => {
        return data.data;
      })
    )
  }


}

