import { Component, OnInit } from '@angular/core';
import { ToastService, Message } from '../toast.service';

@Component({
  selector: 'app-toast-messages',
  templateUrl: './toast-messages.component.html',
  styleUrls: ['./toast-messages.component.scss']
})
export class ToastMessagesComponent implements OnInit {

  messages: Message[];

  constructor(private toast: ToastService) { }

  ngOnInit() {
    this.messages = this.toast.getMessages();
  }

  dismiss(message) {
    this.toast.dismiss(message);
  }

  trackByFn(index, item) {
    return index;
  }

}
