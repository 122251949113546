import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(private router: Router, private user: UserService) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        const userRoles: string[] = this.user.roles;
        let acceptedRoles: string[] = route.data.acceptedRoles;
        acceptedRoles = acceptedRoles.map((el) => {
            return el.toLowerCase();
        });

        for (let i = 0; i < userRoles.length; i++) {
            if (acceptedRoles.indexOf(userRoles[i]) !== -1) {
                return true;
            }
        }
        this.router.navigate(['']);
        return false;

    }
}
