import { Injectable } from '@angular/core';


export class Message {
  content: string;
  style: string;

  constructor(content, style?) {
    this.content = content;
    this.style = style || 'info';
  }
}



@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private messages: Message[] = [];
  constructor() { }

  getMessages() {
    return this.messages;
  }

  // style: info&warning || danger&failed&error || success
  showMessage(content, style?: string, time: number = 3000) {
    // logic to not repeat same message multipletimes
    let isExist = false;
    this.messages.forEach(oldMessage => {
      if (oldMessage.content === content) {
        isExist = true;
        return;
      }
    });
    if (isExist) {
      return;
    }
    // create and show new message
    const message = new Message(content, style);
    this.messages.push(message);
    setTimeout(() => {
      this.dismiss(message);
    }, time);
  }


    showError({ error, defaultMsg }: {error: any, defaultMsg: string}, style?: string, time: number = 3000) {
      let content = defaultMsg || 'Error, try again.';
      if (error && error.error && error.error.errors) {
        const obj = error.error.errors;
        const firstProp = obj[Object.keys(obj)[0]];
        if (firstProp) {
          content = firstProp[0];
        }
      }
      this.showMessage(content, style, time);
    }

  dismiss(message: Message) {
    const index = this.messages.indexOf(message);
    if (index > -1) {
      this.messages.splice(index, 1);
    }
  }

}



