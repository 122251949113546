import { AuthService } from '../services/auth.service';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { Subject, Observable, empty, throwError, Subscription } from 'rxjs';
import { ToastService } from '../../global/toast.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class InterceptorHandle401Service implements HttpInterceptor, OnDestroy {

    baseUrl = environment.baseUrl;
    refreshTokenInProgress = false;
    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    refreshSubscribtion: Subscription;

    constructor(private auth: AuthService, private toast: ToastService) {
    }

    ngOnDestroy() {
        if(this.refreshSubscribtion) {
            this.refreshSubscribtion.unsubscribe();
        }
    }


    refreshToken() {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.refreshSubscribtion = this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;
            return this.auth.refreshToken()
                .pipe(
                    tap(() => {
                        // on success, token refresh successfully
                        this.refreshTokenInProgress = false;
                        this.tokenRefreshedSource.next();
                    }, (error) => {
                        // on error, token refresh failed
                        this.refreshTokenInProgress = false;
                        this.tokenRefreshedSource.complete();
                    })
                );
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Handle response
        return next.handle(request)
            .pipe(
                catchError(error => {

                    // that's mean that we have invalid refresh token
                    if (request.url === (this.baseUrl + 'auth/logout')) {
                        this.auth.logout(true, false);
                        this.toast.showMessage('Your session is expired', 'info', 5000);
                        return throwError(error);
                    }

                    // that's mean that we have invalid refresh token
                    if ((request.url === this.baseUrl + 'auth/refresh') && (error.status === 401 || error.status === 500)) {
                        this.auth.logout(true, false);
                        this.toast.showMessage('Your session is expired', 'info', 5000);
                        return throwError(error);
                    }

                    // need to refresh token
                    if (error.status === 401) {
                        return this.refreshToken().pipe(
                            switchMap(() => {
                                // repeat all failed request, and add them Auth header
                                return next.handle(this.addTokenToFailedRequest(request));
                            }),
                            catchError(() => {
                                return empty();
                            })
                        );
                    } // end 401

                    return throwError(error);
                })
            );
    }

    private addTokenToFailedRequest(request) {
        const token = localStorage.getItem('token');
        if (token && token !== 'undefined') {
            request = request.clone({
                setHeaders: {
                    Authorization: token
                }
            });
        }
        return request;
    }


}
