import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticatedRequiredGuard implements CanActivate {
    constructor(private router: Router, private auth: AuthService) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.auth.isLoggedIn) {
            this.router.navigate(['/auth/login']);
            return false;
        }
        return true;
    }
}
