import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { User } from '../models/User';
import { defaultLogo, defaultColor, defaultName } from '../../shared/config/global.variables';



@Injectable()
export class UserService {


  private userRoleSubject = new BehaviorSubject<string[]>(this.roles);
  public userRole = this.userRoleSubject.asObservable().pipe(distinctUntilChanged());

  get roles(): string[] {
    if (!this.me || !this.me.roles || !this.me.roles.data) {
      return [];
    }
    const roles = this.me.roles.data.map((el) => {
      return el.name.toLowerCase();
    });
    return roles;
  }

  get headerData() {
    const me = this.me;
    if (me && me.UserSubGroups && me.UserSubGroups.data && me.UserSubGroups.data.color) {
      return me.UserSubGroups.data;
    }
    return {
      color: defaultColor,
      logo: defaultLogo,
      subgroup_name: defaultName
    };
  }

  get me(): User {
    const me: any = localStorage.getItem('me');
    if (!me || me === 'undefined') {
      return null;
    }
    return JSON.parse(me);
  }


  setMe(me: User = null) {
    if (!me) {
      this.removeMe();
      return;
    }
    localStorage.setItem('me', JSON.stringify(me));
    this.userRoleSubject.next(this.roles);
  }

  private removeMe(): void {
    localStorage.removeItem('me');
    this.userRoleSubject.next([]);
  }

}
