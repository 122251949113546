import { AuthModule } from './auth/auth.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { ToastMessagesComponent } from './global/toast-messages/toast-messages.component';
import { ModalService } from './global/modal.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

@NgModule({
  declarations: [
    AppComponent,
    ToastMessagesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    AuthModule,
    BrowserAnimationsModule,
    Angulartics2Module.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    ModalService,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy}
  ]
})
export class AppModule { }
