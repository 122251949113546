import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

// usage: for example, login page can visit ONLY UnAuthenticated users
@Injectable()
export class NotAuthenticatedOnlyGuard implements CanActivate {
    constructor(private router: Router, private auth: AuthService) {
    }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.auth.isLoggedIn) {
            const baseRoorForAuthUsers = '';
            this.router.navigate([baseRoorForAuthUsers]);
            return false;
        }
        return true;
    }
}
