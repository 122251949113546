// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://localhost:9000/api/',
  baseUrl: 'https://dev-api.uefaresearch.com/api/',
  usersGuide: '../../../../assets/User_Guide_Clubs_Update_Final.pdf',
  helpLink: 'mailto:research@uefa.ch?cc=igor.masnjak@uefa.ch,elodie.malle@uefa.ch,Ida.Dahl@ecaeurope.com,&subject=Help%20request%20related%20to%20uefaresearch.com&body=Please%20write%20your%20request%20in%20short%20and%20we%20will%20get%20back%20to%20you%20shortly.%20Many%20thanks.%0D%0A%0D%0A',
  hideUBPdata: true,
  ga: null,
  termsUrl: 'terms-of-use',
  dataDescription: 'You will be able to see and use all match audience ' +
    'data of matches your club played in UEFA competitions as of season 2018/19.',
  homeTitle: 'UEFA Club Competitions',

  match_audience_data_show_email: true,
  filterDownloadClubsTeams: "Clubs"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
