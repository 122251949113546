import { AuthenticatedRequiredGuard } from './guards/authenticated-required.guard';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { InterceptorHandle401Service } from './interceptors/interceptor-handle-401';
import { InterceptorAddTokenService } from './interceptors/interceptor-add-token';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotAuthenticatedOnlyGuard } from './guards/not-authentificated-only.guard';
import { RoleGuard } from './guards/role.guard';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
  ],
  exports: [
  ],
  providers: [
    UserService,
    AuthService,
    AuthenticatedRequiredGuard,
    NotAuthenticatedOnlyGuard,
    RoleGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorAddTokenService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorHandle401Service,
      multi: true
    }
  ],
})
export class AuthModule { }
