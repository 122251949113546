<div class="toast-container" *ngIf="messages.length > 0">
  <aside 
    *ngFor="let message of messages; trackBy: trackByFn;"
    [ngClass]="message.style">
      <p class="h3">{{ message.content }}</p>
      <i class="material-icons" (click)="dismiss(message)">
          close
        </i>
  </aside>
</div>
