// import { HomeComponent } from './core/pages/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthenticatedRequiredGuard } from './auth/guards/authenticated-required.guard';
// import { RoleGuard } from './auth/guards/role.guard';
import { NotAuthenticatedOnlyGuard } from './auth/guards/not-authentificated-only.guard';
import { RootAdminComponent } from './admin/root-admin/root-admin.component';
// canActivate: [NotAuthenticatedOnlyGuard]

const routes: Routes = [
  {
    path: 'auth', // first/welcome page with login form
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    data: {
    },
    canActivate: [NotAuthenticatedOnlyGuard] // only NOT authentificated users can visit this page
  },
  {
    path: 'recovery/:token',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    data: {
    },
    canActivate: [NotAuthenticatedOnlyGuard] // only NOT authentificated users can visit this page
  },
  // for admin & editor:
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthenticatedRequiredGuard] // only authentificated users can visit this pages
  },
  // for all users:
  {
    path: '',
    loadChildren: () => import('./main-content/main-content.module').then(m => m.MainContentModule),
    data: {
    },
    canActivate: [AuthenticatedRequiredGuard] // only authentificated users can visit this pages
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules // preloading modules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
